// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDj0O2e5AlVyjkmffzxl_fCDe9viTHefRY",
  authDomain: "nwr-qa-env.firebaseapp.com",
  projectId: "nwr-qa-env",
  storageBucket: "nwr-qa-env.appspot.com",
  messagingSenderId: "29781119118",
  appId: "1:29781119118:web:33b60b533cc5315e3af9df",
  measurementId: "G-4BQTRSPL6T"
};

export default firebaseConfig;

